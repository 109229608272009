import * as React from 'react';
import * as background from '../assets/company.jpg';
import * as cn from 'classnames';
import * as mobileBackground from '../assets/company_mobile.png';
import * as office from '../assets/office_small.jpg';
import * as people0 from '../assets/people-one.jpg';
import * as people1 from '../assets/people-two.jpg';

import { ArticleNode, Edge, MyTheme, News, Value } from '../types/types';
import { Col, Hidden, Row } from 'react-grid-system';
import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import Button from '../components/Button';
import Divider from '../components/Text/Divider';
import { Footer } from '../components/Footer';
import Highlighted from '../components/Text/Highlighted';
import JoinUsForm from '../components/Forms/joinUs';
import ModalComponent from '../components/Modal';
import PageHeader from '../components/PageHeader';
import Spacer from '../components/Text/Spacer';
import { graphql } from 'gatsby';

const homePageHeader = {
    background,
    mobileBackground
};

type OwnProps = {
    data: {
        articles: Edge<ArticleNode>;
        news: Value<News>;
    };
};

class CompanyPage extends React.PureComponent<WithSheet<StyleCreator<string, any>> & OwnProps> {
    state = {
        open: false
    };

    onModalToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    render() {
        const { classes } = this.props;
        const { open } = this.state;
        return (
            <div>
                <PageHeader data={homePageHeader} />
                <div className={classes.main}>
                    <div className={classes.container}>
                        <div className={classes.withPadding}>
                            <div className={classes.story}>
                                <div>
                                    <Spacer />
                                    <Highlighted highlightedText="About Us" />
                                    <Spacer />
                                </div>
                                <h1>
                                    Vortexa is a fast-growing team with a unique and exciting makeup that consists of
                                    the very best data scientists, engineers and energy analysts in the industry.
                                </h1>
                                <br />
                                <div>
                                    We were founded by Fabio Kuhn, former Head of Trading Technology and Analytics at
                                    BP, who serves as CEO, and Etienne Amic, former Head of European Energy at JP Morgan
                                    and Mercuria, who acts as Chairman.
                                </div>
                            </div>
                        </div>
                        <Spacer />
                        <div className={cn(classes.withPadding, classes.textCenter)}>
                            <h1>Our unique DNA</h1>
                            <Divider centered={true} />
                            <h3>
                                We believe that human intelligence can be greatly empowered and scaled by the right
                                technology. Vortexa is committed to the idea that relevant, real market experience
                                underpins the successful application of machine learning and algorithms.
                            </h3>
                        </div>
                        <Spacer />
                        <div className={cn(classes.withPadding, classes.content)}>
                            <Row>
                                <Col xs={12} sm={12} md={6}>
                                    <h2>Industry Expertise</h2>
                                    <p>
                                        The energy trading background of our founders is at the core of Vortexa. Our
                                        clients and partners value that we come from within the industry. In fast paced
                                        energy markets, time is of the essence. Our team of experienced market analysts
                                        come from some of the biggest names in the industry and work closely with
                                        clients and partners to help them succeed.
                                    </p>
                                </Col>
                                <Col xs={12} sm={12} md={6}>
                                    <img className={classes.image} src={people0} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <Hidden xs sm>
                        <Spacer />
                    </Hidden>
                    <div className={classes.container}>
                        <div className={cn(classes.withPadding, classes.content)}>
                            <Row>
                                <Col xs={12} sm={12} md={6}>
                                    <img className={cn(classes.image)} src={people1} />
                                </Col>
                                <Col xs={12} sm={12} md={6}>
                                    <h2>Technology edge</h2>
                                    <p>
                                        We apply state-of-the-art machine learning and AI algorithms, powerful
                                        engineering and data analysis to provide real-time cargo tracking and oil flows
                                        data for the whole world. Vortexa processes data on a massive scale - including
                                        feeds from satellites, ports and markets. Data input and outputs are studied in
                                        conjunction with our market and data analysts, allowing us to capture the full
                                        nuances of oil market complexity. Our results are delivered through a sleek,
                                        easy-to-use interface continually evolving to meet emerging needs from our
                                        clients and partners.
                                    </p>
                                </Col>
                            </Row>
                            <Spacer />
                            <div className={cn(classes.withPadding, classes.textCenter)}>
                                <h1>Join us</h1>
                                <Divider centered={true} />
                                <h3>Help us redefine the world's energy markets</h3>
                                <Spacer />
                                <Row>
                                    <Col xs={12}>
                                        <p className={classes.textLeft}>
                                            Do you enjoy solving complex problems? Are you always learning and adapting?
                                            Are you a pioneer?
                                        </p>
                                    </Col>
                                    <Col xs={12}>
                                        <p className={classes.textLeft}>
                                            At Vortexa, we work at the frontier of technology applied to the energy
                                            markets and together we are creating the future of our industry.
                                        </p>
                                    </Col>
                                    <Col xs={12}>
                                        <Button onClick={() => this.onModalToggle()}>Get in touch</Button>
                                    </Col>
                                </Row>
                            </div>
                            <Spacer />
                            <img className={cn(classes.office, classes.container)} src={office} />
                            <Spacer />
                        </div>
                    </div>
                    <Footer />
                </div>
                <ModalComponent open={open} close={() => this.onModalToggle()}>
                    <JoinUsForm close={() => this.onModalToggle()} />
                </ModalComponent>
            </div>
        );
    }
}

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    main: {
        marginTop: '70vh',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
        backgroundColor: theme.palette.basic.white
    },
    container: {
        ...theme.content,
        ...theme.narrowContent
    },
    withPadding: {
        padding: theme.basePadding
    },
    textCenter: {
        textAlign: 'center'
    },
    dividerContainer: {
        width: '50%'
    },
    story: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    text: {
        flexGrow: 1,
        flexBasis: '1px',
        minWidth: '250px'
    },
    textLeft: {
        margin: `${theme.basePadding}px 0`,
        textAlign: 'left'
    },
    image: {
        display: 'flex',
        width: '100%',
        flexGrow: 1,
        marginTop: theme.basePadding
    },
    office: {
        width: '100%',
        height: 'auto'
    }
});

export default injectSheet(styles)(CompanyPage);
export const query = graphql`
    query {
        news: butterCollection(key: { eq: "news" }) {
            ...News
        }
        articles: allButterPost(limit: 2, sort: { fields: date, order: ASC }) {
            ...Articles
        }
    }
`;
